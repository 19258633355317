<template>
   <div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" :class="search.result.length > 0 ? '' : 'empty'" data-toggle="dropdown" aria-expanded="false">
            <i class="far fa-search font-17"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body">
               <div class="row m-0">
                  <div class="col-8 col-md-10 px-1">
                     <label class="mb-1"><i class="far fa-hashtag font-12 color-theme mr-1"></i> {{ $t("ecommerceProducts.search.label") }}</label>
                     <input type="text" class="form-control rounded" v-model="search.name" maxlength="200" @keyup.enter="searchProducts" :placeholder="$t('ecommerceProducts.search.placeholder')" />
                  </div>
                  <div class="col-2 col-md-1 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100 rounded" @click="searchProducts">
                        <i class="fa fa-search font-13"></i>
                     </button>
                  </div>
                  <div class="col-2 col-md-1 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100 rounded" @click="abrirModal(null)">
                        <i class="fa fa-plus font-13"></i>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

		<div class="col-12" v-if="search.result.length > 0">
         <div class="cards-1">
            <product v-for="(product, index) in search.result" :key="index" :product="product" :index="index" @edit="abrirModal($event)" />
			</div>
		</div>
      <div class="col-12 my-5 text-center" v-else>{{ $t("ecommerceProducts.search.noRes") }}</div>

      <!-- modalProduct -->
      <div class="modal fade" id="modalProduct" aria-labelledby="modalProductLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalProductLabel">Product - {{ modal.name ? modal.name : 'Add' }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-12">
                        <div class="row">
                           <div class="col-xl-6 mb-2">
                              <div class="card h-100 mb-0">
                                 <div class="card-body p-3">
                                    <label><i class="far fa-hashtag color-theme font-12 mr-1"></i> {{ $t("ecommerceProducts.modal.name") }} *</label>
                                    <input type="text" class="form-control" v-model="modal.name" />
                                 </div>
                              </div>
                           </div>
                           <div class="col-sm-6 col-xl-3 mb-2">
                              <div class="card h-100 mb-0">
                                 <div class="card-body p-3">
                                    <label><i class="far fa-money-bill-wave-alt color-theme font-12 mr-1"></i> {{ $t("ecommerceProducts.modal.price") }} *</label>
                                    <input type="text" class="form-control" v-model="modal.price" v-mask="['#.##', '##.##', '###.##', '####.##', '#####.##', '######.##', '#######.##']" />
                                 </div>
                              </div>
                           </div>
                           <div class="col-sm-6 col-xl-3 mb-2">
                              <div class="card h-100 mb-0">
                                 <div class="card-body p-3">
                                    <label><i class="far fa-file color-theme font-12 mr-1"></i> {{ $t("ecommerceProducts.modal.category") }} *</label>
                                    <v-select title="Desktop" :options="ecommerceCategoriesList" v-model="modal.category" />
                                    <select title="Mobile" class="form-control" v-model="modal.category">
                                       <option v-for="(option, index) in ecommerceCategoriesList" :key="index" :value="option">{{ option }}</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-7 col-xl-8 mb-2">
                              <div class="card h-100 mb-0">
                                 <div class="card-body p-3">
                                    <div class="row">
                                       <div class="col-sm-8 mb-3 mb-sm-0">
                                          <label><i class="far fa-lock color-theme font-12 mr-1"></i> {{ $t("ecommerceProducts.modal.permission") }}</label>
                                          <v-select title="Desktop" :options="permissionsList" v-model="modal.permission" label="name" :reduce="p => p.id" />
                                          <select title="Mobile" class="form-control" v-model="modal.permission">
                                             <option v-for="(option, index) in permissionsList" :key="index" :value="option.id">{{ option.name }}</option>
                                          </select>
                                       </div>
                                       <div class="col-sm-4">
                                          <label><i class="far fa-clock color-theme font-12 mr-1"></i> {{ $t("ecommerceProducts.modal.permissionDuration") }}</label>
                                          <input type="text" class="form-control" v-model="modal.permissionDuration" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-5 col-xl-4 mb-2">
                              <div class="card h-100 mb-0">
                                 <div class="card-body p-3">
                                    <label><i class="far fa-link color-theme font-12 mr-1"></i> {{ $t("ecommerceProducts.modal.urlDownload") }}</label>
                                    <input type="text" class="form-control" v-model="modal.linkDownload" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12">
                        <div class="row">
                           <div class="col-6 col-xl-3 mb-2">
                              <div class="card h-100 mb-0">
                                 <div class="card-body p-3">
                                    <label><i class="far fa-image-polaroid color-theme font-12 mr-1"></i> {{ $t("ecommerceProducts.modal.icon") }}</label>
                                    <div class="ratio ratio-1x1">
                                       <UploadImageDeck @attValor="modal.icon = $event" :model="modal.icon" identificador="icon" width="100%" height="100%" :minAspectRatio="1 / 1" :maxAspectRatio="1 / 1" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-6 col-xl-3 mb-2">
                              <div class="card h-100 mb-0">
                                 <div class="card-body p-3">
                                    <label><i class="far fa-image color-theme font-12 mr-1"></i> Banner 01</label>
                                    <div class="ratio ratio-1x1">
                                       <UploadImageDeck @attValor="modal.banner1 = $event" :model="modal.banner1" identificador="banner1" width="100%" height="100%" :minAspectRatio="1 / 1" :maxAspectRatio="1 / 1" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-6 col-xl-3 mb-2">
                              <div class="card h-100 mb-0">
                                 <div class="card-body p-3">
                                    <label><i class="far fa-image color-theme font-12 mr-1"></i> Banner 02</label>
                                    <div class="w-100 text-center my-5" v-if="modal.banner1 == null">{{ $t("ecommerceProducts.modal.bannerValidation") }}</div>
                                    <div class="ratio ratio-1x1" v-else>
                                       <UploadImageDeck @attValor="modal.banner2 = $event" :model="modal.banner2" identificador="banner2" width="100%" height="100%" :minAspectRatio="1 / 1" :maxAspectRatio="1 / 1" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-6 col-xl-3 mb-2">
                              <div class="card h-100 mb-0">
                                 <div class="card-body p-3">
                                    <label><i class="far fa-image color-theme font-12 mr-1"></i> Banner 03</label>
                                    <div class="w-100 text-center my-5" v-if="modal.banner2 == null">{{ $t("ecommerceProducts.modal.bannerValidation") }}</div>
                                    <div class="ratio ratio-1x1" v-else>
                                       <UploadImageDeck @attValor="modal.banner3 = $event" :model="modal.banner3" identificador="banner3" width="100%" height="100%" :minAspectRatio="1 / 1" :maxAspectRatio="1 / 1" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12">
                        <div class="card mb-0">
                           <div class="card-body p-3">
                              <div class="row">
                                 <div class="w-max-content">
                                    <label class="switch"><input type="checkbox" v-model="modal.showHomePage"><span class="slider"></span></label>
                                 </div>
                                 <div class="col">
                                    <label class="mb-0 ml-2">{{ $t("ecommerceProducts.modal.showHomePage") }}</label>
                                 </div>
                                 <div class="col-12"><hr></div>
                                 <div class="col-12 mb-3">
                                    <TextAreaDeck :model="modal.resume" position_emoji="top" :label="`<i class='far fa-sticky-note color-theme font-13 mr-1'></i> ${$t('ecommerceProducts.modal.resume')}`" @attValor="modal.resume = $event;" />
                                 </div>
                                 <div class="col-12">
                                    <label class="cursor-pointer" @click="modalConfig.toggle = !modalConfig.toggle">
                                       <span><i class="far fa-ellipsis-h color-theme font-12 mr-1"></i> {{ $t("ecommerceProducts.modal.description") }}</span>
                                       <span class="float-right"><i class="fas color-theme" :class="modalConfig.toggle ? 'fa-angle-down' : 'fa-angle-left'"></i></span>
                                    </label>
                                    <EditorHTML :value="modal.description" @attValue="modal.description = $event" v-if="modalConfig.toggle" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="saveProduct">{{ $t("serverSettings.editMessage.buttons.save") }}</button>
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.alterSettings.permission.button") }}</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import EditorHTML from '@/components/EditorHTML.vue'
import product from '@/components/ecommerce/Product.vue'
import TextAreaDeck from '@/components/TextAreaDeck.vue'
import UploadImageDeck from '@/components/UploadImageDeck.vue'

export default {
   name: 'Ecommerce_Products',
   data : function () {
      return {
         search: {'idServer': null, 'name': '', 'result': []},
         modal: {
            'id': null, 'price': '0.00', 'description': '', 'name': '', 'icon': null, 'banner1': null, 'banner2': null, 'banner3': null, 'category': null, 
            'linkDownload': '', 'permission': null, 'permissionDuration': '30', 'showHomePage': false, 'idServer': null, 'resume': ''
         },
         modalConfig: {'toggle': false}
      }
   },
   computed: {
		...mapState({
         ecommerceCategoriesList: state => state.ecommerceCategoriesList,
         permissionsList: state => state.permissionsList,
			urlRest: state => state.urlRest,
         serverS: state => state.serverS
		})
	},
   components: {
      product, UploadImageDeck, EditorHTML, TextAreaDeck
   },
   methods: {
      searchProducts : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.search.idServer = this.serverS.id_s

         Object.assign(this.$data.search.result, this.$options.data.apply(this).search.result)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/searchProducts',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.search

         }).then((response) => {
            this.search.result = response.data
            
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      abrirModal : function (product) {
         if (product) {
            this.modal = JSON.parse(JSON.stringify(product))
         } else {
            Object.assign(this.$data.modal, this.$options.data.apply(this).modal)
         }
         
         $("#modalProduct").modal('show')
         
         this.modalConfig = {'toggle': false}
         this.modal.idServer = this.serverS.id_s
      },
      saveProduct : function () {
         if (this.modal.name == null || String(this.modal.name).trim().length == 0 || this.modal.price == null || String(this.modal.price).trim().length == 0 ||
            this.modal.category == null || String(this.modal.category).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t("ecommerceProducts.modal.validation")
            })

            return
         }

         this.$store.dispatch('alternarTelaCarregamento', true)
         $("#modalProduct").modal('hide')

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/saveProduct',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.modal

         }).then(() => {
            this.searchProducts()
            
            this.$toast.fire({
               icon: 'success',
               title: this.$t("ecommerceProducts.modal.saved")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      }
   },
   mounted() {
      this.$store.dispatch('getEcommerceCategories')
      this.$store.dispatch('getPermissions')
      this.$store.dispatch('getChannels')

      $('#search-nav .dropdown-menu').on('click', function(e) {
         if (e.target.nodeName != 'BUTTON') {
            e.stopPropagation();
         }
      });
   }
}

</script>