<template>
   <div class="card mb-0" ref="product">
      <div class="card-buttons-top">
         <div class="card-body p-3">
            <div id="buttons" class="w-100 text-center pb-5">
               <div class="btn btn-primary font-13 m-2" @click="edit">
                  <i class="fal fa-pen font-11 mr-1"></i> {{ $t('ecommerceProducts.modal.edit') }}
               </div>
               <div class="btn btn-outline-primary font-13 m-2" @click="sendDiscord">
                  <i class="fab fa-discord font-11 mr-1"></i> {{ $t('ecommerceProducts.modal.send') }}
               </div>
               <div class="btn btn-danger font-13 m-2" @click="remove">
                  <i class="fal fa-trash font-11 mr-1"></i> {{ $t('ecommerceProducts.modal.delete') }}
               </div>
            </div>
            
            <h6 class="limitador-2 font-20">{{ product.name }}</h6>
            <div class="row card-info-bottom align-items-center">
               <div class="col-6">
                  <h6 class="limitador-2 font-16"><small class="color-theme">R$</small> {{ parseFloat(product.price).toFixed(2) }}</h6>
               </div>
               <div class="col-6 text-right">
                  <i class="far fa-home font-16 color-theme" v-if="product.showHomePage"></i>
               </div>
               <div class="col-12 mt-1">
                  <p class="limitador-1">
                     <i class="far fa-file font-11 color-theme mr-1"></i><strong class="mx-1">{{ $t("ecommerceProducts.modal.category") }}:</strong>
                     <span> {{ product.category != null ? product.category : 'Unknown' }}</span>
                  </p>
               </div>
            </div>
         </div>
      </div>
      <img :src="product.icon == null ? '' : product.icon" class="card-img-top" alt="product" @error="imageError">
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Product',
   props: ['product', 'index'],
   computed: {
		...mapState({
			channelsList: state => state.channelsList,
			urlRest: state => state.urlRest
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      edit: function () {
         this.$emit('edit', this.product)
      },
      sendDiscord : function () {
         Swal.fire({
            icon: 'info',
            title: this.$t("ecommerceProducts.modal.selectChannel"),
            input: 'select',
            inputOptions: this.channelsList.reduce((acc, channel) => {
               acc[channel.id] = channel.name;
               return acc;
            }, {}),
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel"),
            inputValidator: (value) => {
               if (!value) {
                  return this.$t('ecommerceProducts.modal.invalidChannel')
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'servicos/sendProductToDiscord',
                  headers: {
                     'Content-Type': 'application/json'
                  },
                  data: {"id": this.product.id, "idChannel": result.value}

               }).then(() => {
                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t('ecommerceProducts.modal.sendSuccess')
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t("serverSettings.swal.expired")
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
      },
      remove: function () {
         Swal.fire({
            icon: 'warning',
            title: this.$t("ecommerceProducts.modal.deleteDesc"),
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel")

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest + 'servicos/deleteProduct',
                  params: {
                     id: this.product.id
                  }
               }).then(() => {
                  this.$refs.product.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t("ecommerceProducts.modal.deleted")
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t("serverSettings.swal.expired")
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Error: ' + error.response.status
                        });
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     });
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      }
   }
}

</script>

<style scoped>

.card-buttons-top {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: flex-end;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: #0008;
   transition: 0.3s;
}

.card-buttons-top:hover {
   background: #0009;
}

.card-buttons-top:hover #buttons {
   display: block !important;
}

.card-buttons-top #buttons {
   display: none !important;
}

.card-info-bottom a {
   color: #ddd;
   cursor: pointer;
}

.card-info-bottom a:hover {
   text-decoration: underline !important;
}

h6 {
   color: #ddd;
   margin-bottom: 10px !important;
   font-weight: 700 !important;
   filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
}

p {
   color: #eee;
   font-size: 13px !important;
   margin-bottom: 0 !important;
   filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
   line-height: 1.4;
}

img {
   aspect-ratio: 3/4;
   object-fit: cover;
}

</style>